import { Link } from 'react-router-dom'
import main from '../assets/images/main.svg'
import Wrapper from '../assets/wrappers/LandingPage'
import { Logo } from '../components'

const Landing = () => {
  return (
    <Wrapper>
      <nav>
        <Logo />
        <Link to='/register'>Register</Link>
        <Link to='/'>Landing</Link>
        <Link to='/dashboard'>Dashboard</Link>
      </nav>
      <div className='container page'>
        <div className='info'>
          <h1>
            Job <span> Tracking</span> App
          </h1>
          <p>
            I'm baby vaporware everyday carry VHS neutral milk hotel
            farm-to-table, umami cliche cloud bread photo booth lo-fi ennui
            hexagon disrupt.
          </p>
          <button className='btn btn-hero'>Login/Register</button>
        </div>
        <img src={main} alt='job-quester-main' className='img main-img'></img>
      </div>
    </Wrapper>
  )
}

export default Landing
